<template>
  <div class="d-flex align-center justify-center secondary-lighten rounded">
    <image-carousel class="fill-height" :ad="ad" />
  </div>
</template>

<script>
  import Ads from '@/services/classes/Ads.js'
  import ImageCarousel from '@/components/ads/ImageCarousel.vue'

  export default {
    name: 'NativeAdCreativePreview',
    components: { ImageCarousel },
    props: {
      ad: {
        type: Ads,
        required: true
      }
    },
    data() {
      return {
        hasCreativeImage: false
      }
    },
    created() {
      this.hasCreativeImage = this.ad.model.creatives.some((creative) => creative.image.src)
    }
  }
</script>
